<template>
  <view-base ref="base">
    <div class="mb-3">
      <h4><b>{{ $t('Pedidos entregues hoje') }}</b> ({{ ordersToday.length }} - {{ totalToday.toFixed(2) }}€)</h4>
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersToday"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          :empty-message="$t('Nenhuma entrega realizada hoje')"
        />
      </div>
    </div>
    <div class="mb-3">
      <h4><b>{{ $t('Pedidos entregues ontem') }} - {{ yesterday }}</b> ({{ ordersYesterday.length }} - {{ totalYesterday.toFixed(2) }}€)</h4>
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersYesterday"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          :empty-message="$t('Nenhuma entrega realizada ontem')"
        />
      </div>
    </div>
    <div class="mb-3">
      <h4><b>{{ $t('Pedidos entregues nessa semana') }} - {{ currentMonday }} - {{ currentSunday }}</b> ({{ ordersCurrentWeek.length }} - {{ totalCurrentWeek.toFixed(2) }}€)</h4>
      <chart-orders :options="chartOptions" :chart-data="chartDataCurrentWeek" :styles="myStyles" />
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersCurrentWeek"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          :empty-message="$t('Nenhuma entrega nesta semana')"
        />
      </div>
    </div>
    <div class="mb-3">
      <h4><b>{{ $t('Pedidos entregues na última semana') }} - {{ lastMonday }} - {{ lastSunday }}</b> ({{ ordersLastWeek.length }} - {{ totalLastWeek.toFixed(2) }}€)</h4>
      <chart-orders :options="chartOptions" :chart-data="chartDataLastWeek" :styles="myStyles" />
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersLastWeek"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          :empty-message="$t('Nenhuma entrega na última semana')"
        />
      </div>
    </div>
    <div>
      <h4><b>{{ $t('Pedidos entregues nos últimos 15 dias') }}</b> ({{ ordersLast15.length }} - {{ totalLast15.toFixed(2) }}€)</h4>
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersLast15"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          :empty-message="$t('Nenhuma entrega nos últimos 15 dias')"
        />
      </div>
    </div>
  </view-base>
</template>

<style scoped>
.card {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
  background: #F0F0F0;
  border-radius: 3px 3px 4px 4px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid white;

  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import Ripple from 'vue-ripple-directive'

import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ViewBase from '@/views/ViewBase.vue'

import ChartOrders from '@/views/components/ChartOrders.vue'

const AdvancedTable = () => import('@core/components/AdvancedTableComponent.vue')

export default {
  components: {
    ViewBase,
    AdvancedTable,
    ChartOrders,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ordersToday: [],
      totalToday: 0,
      ordersLast15: [],
      totalLast15: 0,
      ordersYesterday: [],
      totalYesterday: 0,
      ordersCurrentWeek: [],
      totalCurrentWeek: 0,
      myStyles: {
        height: '200px',
        width: '100%',
        position: 'relative',
      },
      chartDataLast15: {
        datasets: [],
      },
      chartDataCurrentWeek: {
        datasets: [],
      },
      chartDataLastWeek: {
        datasets: [],
      },
      ordersLastWeek: [],
      totalLastWeek: 0,
      columns: [{
          title: 'Loja',
          name: 'storeName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Cliente',
          name: 'eater_name',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
        {
            title: 'Hora',
            name: 'formatDate',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
        {
            title: 'Cidade',
            name: 'delivered_city',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
        {
            title: 'Valor',
            name: 'driver_amount',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
        {
            title: 'Pagamento',
            name: 'paymentStatus',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
      ],
      sortColunms: [],
      filteredSize: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        height: 300,
        legend: {
          display: false,
        },
      },
    }
  },
  computed: {
    currentMonday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate())
      return moment(today).format('DD/MM/YYYY')
    },
    yesterday: () => {
      const today = new Date()
      today.setDate(today.getDate() - 1)
      return moment(today).format('DD/MM/YYYY')
    },
    currentSunday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() + 6)
      return moment(today).format('DD/MM/YYYY')
    },
    lastMonday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() - 7)
      return moment(today).format('DD/MM/YYYY')
    },
    lastSunday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() - 1)
      return moment(today).format('DD/MM/YYYY')
    },
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      this.driverLoadOrdersDeliveredToday()
      this.driverLoadOrdersDeliveredYesterday()
      this.driverLoadOrdersDeliveredCurrentWeek()
      this.driverLoadOrdersDeliveredLastWeek()
      this.driverLoadOrdersDeliveredLast15()
    },
    driverLoadOrdersDeliveredToday() {
      const self = this
      this.$refs.base.showLoading()
      self.totalToday = 0
      useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/today`)
      .then(response => {
        self.ordersToday = response.data.orders ?? []
        self.ordersToday.forEach((order, index) => {
          self.totalToday += parseFloat(self.ordersToday[index].driver_amount)
          self.ordersToday[index].storeName = self.ordersToday[index].store.name
          self.ordersToday[index].formatDate = moment(self.ordersToday[index].delivered_time).format('DD/MM/YYYY HH:mm')
          self.ordersToday[index].paymentStatus = self.ordersToday[index].paid === 1 ? `pago ${moment(self.ordersToday[index].paid_at).format('DD/MM/YYYY')}` : 'pendente'
          self.ordersToday[index].link = `https://www.google.com/maps/search/${self.ordersToday[index].delivered_lat},${self.ordersToday[index].delivered_lng}`
        })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    driverLoadOrdersDeliveredYesterday() {
      const self = this
      this.$refs.base.showLoading()
      self.totalYesterday = 0
      useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/yesterday`)
      .then(response => {
        self.ordersYesterday = response.data.orders ?? []
        self.ordersYesterday.forEach((order, index) => {
          self.totalYesterday += parseFloat(self.ordersYesterday[index].driver_amount)
          self.ordersYesterday[index].storeName = self.ordersYesterday[index].store.name
          self.ordersYesterday[index].formatDate = moment(self.ordersYesterday[index].delivered_time).format('DD/MM/YYYY HH:mm')
          self.ordersYesterday[index].paymentStatus = self.ordersYesterday[index].paid === 1 ? `pago ${moment(self.ordersYesterday[index].paid_at).format('DD/MM/YYYY')}` : 'pendente'
          self.ordersYesterday[index].link = `https://www.google.com/maps/search/${self.ordersYesterday[index].delivered_lat},${self.ordersYesterday[index].delivered_lng}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    driverLoadOrdersDeliveredCurrentWeek() {
      const self = this
      this.$refs.base.showLoading()
      self.totalCurrentWeek = 0
      useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/current_week`)
      .then(response => {
        self.ordersCurrentWeek = response.data.orders ?? []
        self.ordersCurrentWeek.forEach((order, index) => {
          self.totalCurrentWeek += parseFloat(self.ordersCurrentWeek[index].driver_amount)
          self.ordersCurrentWeek[index].storeName = self.ordersCurrentWeek[index].store.name
          self.ordersCurrentWeek[index].formatDate = moment(self.ordersCurrentWeek[index].delivered_time).format('DD/MM/YYYY HH:mm')
          self.ordersCurrentWeek[index].paymentStatus = self.ordersCurrentWeek[index].paid === 1 ? `pago ${moment(self.ordersCurrentWeek[index].paid_at).format('DD/MM/YYYY')}` : 'pendente'
          self.ordersCurrentWeek[index].link = `https://www.google.com/maps/search/${self.ordersCurrentWeek[index].delivered_lat},${self.ordersCurrentWeek[index].delivered_lng}`
        })

        const newData = {
          labels: [],
          datasets: [{
            backgroundColor: '#004d91',
            data: [],
          }],
        }
        Object.keys(response.data.report).forEach(key => {
          newData.labels.push(response.data.report[key].date)
          newData.datasets[0].data.push(response.data.report[key].total)
        })
        self.chartDataCurrentWeek = newData
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    driverLoadOrdersDeliveredLastWeek() {
      const self = this
      this.$refs.base.showLoading()
      self.totalLastWeek = 0
      useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/last_week`)
      .then(response => {
        self.ordersLastWeek = response.data.orders ?? []
        self.ordersLastWeek.forEach((order, index) => {
          self.totalLastWeek += parseFloat(self.ordersLastWeek[index].driver_amount)
          self.ordersLastWeek[index].storeName = self.ordersLastWeek[index].store.name
          self.ordersLastWeek[index].formatDate = moment(self.ordersLastWeek[index].delivered_time).format('DD/MM/YYYY HH:mm')
          self.ordersLastWeek[index].paymentStatus = self.ordersLastWeek[index].paid === 1 ? `pago ${moment(self.ordersLastWeek[index].paid_at).format('DD/MM/YYYY')}` : 'pendente'
          self.ordersLastWeek[index].link = `https://www.google.com/maps/search/${self.ordersLastWeek[index].delivered_lat},${self.ordersLastWeek[index].delivered_lng}`
          })

        const newData = {
          labels: [],
          datasets: [{
            backgroundColor: '#004d91',
            data: [],
          }],
        }
        Object.keys(response.data.report).forEach(key => {
          newData.labels.push(response.data.report[key].date)
          newData.datasets[0].data.push(response.data.report[key].total)
        })
        self.chartDataLastWeek = newData
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    driverLoadOrdersDeliveredLast15() {
      const self = this
      this.$refs.base.showLoading()
      self.totalLast15 = 0
      useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/last_15`)
      .then(response => {
        self.ordersLast15 = response.data.orders ?? []
        self.ordersLast15.forEach((order, index) => {
          self.totalLast15 += parseFloat(self.ordersLast15[index].driver_amount)
          self.ordersLast15[index].storeName = self.ordersLast15[index].store.name
          self.ordersLast15[index].formatDate = moment(self.ordersLast15[index].delivered_time).format('DD/MM/YYYY HH:mm')
          self.ordersLast15[index].paymentStatus = self.ordersLast15[index].paid === 1 ? `pago ${moment(self.ordersLast15[index].paid_at).format('DD/MM/YYYY')}` : 'pendente'
          self.ordersLast15[index].link = `https://www.google.com/maps/search/${self.ordersLast15[index].delivered_lat},${self.ordersLast15[index].delivered_lng}`
          })

        const newData = {
          labels: [],
          datasets: [{
            backgroundColor: '#004d91',
            data: [],
          }],
        }
        Object.keys(response.data.report).forEach(key => {
          newData.labels.push(response.data.report[key].date)
          newData.datasets[0].data.push(response.data.report[key].total)
        })
        self.chartDataLast15 = newData
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    onOrderSelected(event, data) {
      window.open(data.link, '_blank')
    },
  },
}
</script>
